export enum PermissionsEnum {
  'Ver Cedentes (Root)' = 'readAssignors',
  'Adicionar Cedente' = 'createAssignors',
  'Atualizar Cedente' = 'updateAssignors',
  'Deletar Cedente' = 'deleteAssignors',
  'Atualizar Limite do Cedente' = 'updateAssignorLimits',
  'Atualizar Parâmetros de Operação do Cedente' = 'updateAssignorOperationParams',
  'Atualizar Tarifas de Operação do Cedente' = 'updateAssignorOperationFees',
  'Adicionar Avalistas ao Cedente' = 'createAssignorGuarantors',
  'Atualizar Avalistas do Cedente' = 'updateAssignorGuarantors',
  'Deletar Avalistas do Cedente' = 'deleteAssignorGuarantors',
  'Adicionar Documentos do Cedente' = 'createAssignorDocuments',
  'Atualizar Documentos do Cedente' = 'updateAssignorDocuments',
  'Deletar Documentos do Cedente' = 'deleteAssignorDocuments',
  'Adicionar Contas Bancárias do Cedente' = 'createAssignorBanks',
  'Atualizar Contas Bancárias do Cedente' = 'updateAssignorBanks',
  'Deletar Contas Bancárias do Cedente' = 'deleteAssignorBanks',
  'Adicionar Limites de Sacados por Cedente' = 'createAssignorPayerLimits',
  'Atualizar Limites de Sacado por Cedente' = 'updateAssignorPayerLimits',
  'Deletar Limites de Sacado por Cedente' = 'deleteAssignorPayerLimits',
  'Adicionar Usuários do Cedente' = 'createAssignorUsers',
  'Atualizar Usuários do Cedente' = 'updateAssignorUsers',
  'Adicionar Relatório diário do Cedente' = 'createAssignorReports',
  'Atualizar Relatório diário do Cedente' = 'updateAssignorReports',
  'Enviar Relatório diário do Cedente' = 'sendingAssignorReports',
  'Deletar Usuários do Cedente' = 'deleteAssignorUsers',
  'Ver Holdings (Root)' = 'readHoldings',
  'Adicionar Holdings' = 'createHoldings',
  'Atualizar Holdings' = 'updateHoldings',
  'Deletar Holdings' = 'deleteHoldings',
  'Atualizar Limite de Holings' = 'updateHoldingLimits',
  'Ver Sacados (Root)' = 'readPayers',
  'Adicionar Sacados' = 'createPayers',
  'Atualizar Sacados' = 'updatePayers',
  'Deletar Sacados' = 'deletePayers',
  'Atualizar Limite de Sacados' = 'updatePayerLimits',
  'Ver Fornecedores (Root)' = 'readSuppliers',
  'Adicionar Fornecedores' = 'createSuppliers',
  'Atualizar Fornecedores' = 'updateSuppliers',
  'Deletar Fornecedores' = 'deleteSuppliers',
  'Ver Coordenadores (Root)' = 'readCoordinators',
  'Adicionar Coordenadores' = 'createCoordinators',
  'Atualizar Coordenadores' = 'updateCoordinators',
  'Deletar Coordenadores' = 'deleteCoordinators',
  'Ver Gerentes (Root)' = 'readManagers',
  'Adicionar Gerentes' = 'createManagers',
  'Atualizar Gerentes' = 'updateManagers',
  'Deletar Gerentes' = 'deleteManagers',
  'Ver Agentes (Root)' = 'readAgents',
  'Adicionar Agentes' = 'createAgents',
  'Atualizar Agentes' = 'updateAgents',
  'Deletar Agentes' = 'deleteAgents',
  'Ver Tipos de Documentos (Root)' = 'readDocumentTypes',
  'Adicionar Tipos de Documentos' = 'createDocumentTypes',
  'Atualizar Tipos de Documentos' = 'updateDocumentTypes',
  'Deletar Tipos de Documentos' = 'deleteDocumentTypes',
  'Ver Contas Corrente (Root)' = 'readAccounts',
  'Adicionar Contas Corrente' = 'createAccounts',
  'Atualizar Contas Corrente' = 'updateAccounts',
  'Deletar Contas Corrente' = 'deleteAccounts',
  'Atualizar Configuração de CNAB' = 'updateAccountCnab',
  'Ver Extrato de Conta Corrente' = 'viewAccountStatement',
  'Alterar Saldo de Conta Corrente' = 'updateAccountBalance',
  'Ver Operações (Root)' = 'readOperations',
  'Reabrir Operações' = 'reopenOperation',
  'Adicionar Operações' = 'createOperations',
  'Atualizar Operações' = 'updateOperations',
  'Deletar Operações' = 'deleteOperations',
  'Ver Comitê' = 'viewCommitties',
  'Aprovar Operações' = 'approveOperations',
  'Negar Operações' = 'denyOperations',
  'Pagar Operações' = 'payOperations',
  'Pagar Operações em Lote' = 'payOperationsInBatch',
  'Marcar/Desmarcar título como comissária' = 'setTitleAsUnnotified',
  'Exibir dados administrativos' = 'viewAdministrativeData',
  'Adicionar Aditivos de Operações' = 'createOperationAdditives',
  'Confirmar Assinatura de Aditivos' = 'confirmOperationAdditivesSignatures',
  'Atualizar Tarifas da Operação' = 'updateOperationFees',
  'Atualizar Tarifas de Título' = 'updateTitleTariffs',
  'Deletar Tarifas de Título' = 'deleteTitleTariffs',
  'Adicionar CNAB para registro do nosso número na Operação' = 'cnabCanPrePrintTicketInOperation',
  'Aprovar Títulos da Operação' = 'approveOperationTitles',
  'Reprovar Títulos da Operação' = 'denyOperationTitles',
  'Adicionar Títulos na Operação' = 'createOperationTitles',
  'Remover Títulos na Operação' = 'removeOperationTitles',
  'Editar Títulos da Operação' = 'editOperationTitles',
  'Enviar Títulos da Operação para Confirmação' = 'sendOperationToConfirmation',
  'Remover Títulos da Operação da Confirmação' = 'removeOperationFromConfirmation',
  'Enviar Títulos para Nova Operação' = 'sendOperationTitleToNewOperation',
  'Enviar Títulos para Operação Existente' = 'sendOperationTitleToExistingOperation',
  'Ver Performance de Carteira (Root)' = 'readTitleConfirmation',
  'Performar Títulos' = 'performTitleConfirmations',
  'Ver Esteira de Confirmação e Cobrança (Root)' = 'readBilling',
  'Ver Remessas CNAB (Root)' = 'readCnabCommands',
  'Adicionar Remessa' = 'createCnabCommands',
  'Ver Retornos CNAB (Root)' = 'readCnabReturns',
  'Enviar Remessas' = 'sendCnabReturns',
  'Ver Movimentação de Títulos (Root)' = 'readTitles',
  'Ver Detalhes do Título' = 'viewTitleDetails',
  'Pagar Títulos' = 'payTitles',
  'Prorrogar Títulos' = 'extendTitles',
  'Alterar Vencimento do Título' = 'changeDateTitle',
  'Estornar Prorrogação de Títulos' = 'refundExtendTitles',
  'Gerar Tarifas nos Títulos' = 'generateTitleFees',
  'Estornar Pagamento de Títulos' = 'reverseTitlePayments',
  'Atualizar Agente Cobrador do Título' = 'changeTitleAgents',
  'Enviar Título para Jurídico' = 'sendTitlesToJudicial',
  'Pagar Tarifas' = 'payFees',
  'Ver Movimentação de Tarifas (Root)' = 'readFees',
  'Pagar Títulos em Lote' = 'payTitlesInBatch',
  'Prorrogar Títulos em Lote' = 'extendTitlesInBatch',
  'Enviar Comandos CNAB nos Títulos' = 'sendCnabCommandsInTitles',
  'Estornar Pagamento de Tarifas' = 'reverseFeePayments',
  'Ver Contas a Pagar (Root)' = 'readInvoices',
  'Adicionar Contas a Pagar' = 'createInvoices',
  'Atualizar Contas a Pagar' = 'updateInvoices',
  'Deletar Contas a Pagar' = 'deleteInvoices',
  'Ver Lançamentos Contábeis (Root)' = 'readSends',
  'Adicionar Lançamentos Contábeis' = 'createSends',
  'Atualizar Lançamentos Contábeis' = 'updateSends',
  'Deletar Lançamentos Contábeis' = 'deleteSends',
  'Ver Pendências Financeiras (Root)' = 'readPendencies',
  'Adicionar Pendências Financeiras' = 'createPendencies',
  'Atualizar Pendências Financeiras' = 'updatePendencies',
  'Deletar Pendências Financeiras' = 'deletePendencies',
  'Ver Devolução de Cheques (Root)' = 'readChecksReturned',
  'Adicionar Devolução de Cheques' = 'createChecksReturned',
  'Atualizar Devolução de Cheques' = 'updateChecksReturned',
  'Deletar Devolução de Cheques' = 'deleteChecksReturned',
  'Ver Plano de Contas (Root)' = 'readAccountPlans',
  'Adicionar Plano de Contas' = 'createAccountPlans',
  'Atualizar Plano de Contas' = 'updateAccountPlans',
  'Deletar Plano de Contas' = 'deleteAccountPlans',
  'Ver Eventos Contábeis (Root)' = 'readContabilEvents',
  'Adicionar Eventos Contábeis' = 'createContabilEvents',
  'Atualizar Eventos Contábeis' = 'updateContabilEvents',
  'Deletar Eventos Contábeis' = 'deleteContabilEvents',
  'Ver Cadastro de Empresas (Root)' = 'readCompanies',
  'Adicionar Empresas' = 'createCompanies',
  'Atualizar Empresas' = 'updateCompanies',
  'Deletar Empresas' = 'deleteCompanies',
  'Ver Cadastro de Usuários (Root)' = 'readUsers',
  'Adicionar Usuários' = 'createUsers',
  'Atualizar Usuários' = 'updateUsers',
  'Deletar Usuários' = 'deleteUsers',
  'Ver Configuração de Esteiras (Root)' = 'readBillingSettings',
  'Adicionar Configuração de Esteiras' = 'createBillingSettings',
  'Atualizar Configuração de Esteiras' = 'updateBillingSettings',
  'Deletar Configuração de Esteiras' = 'deleteBillingSettings',
  'Ver Parametrização do Sistema (Root)' = 'readParams',
  'Atualizar Parametrização do Sistema' = 'updateParams',
  'Consultar Protesto (Root)' = 'protestConsult',
  'Consultar Empresas (Root)' = 'companyConsult',
  'Acesso à Confirmação Eletrônica (Root)' = 'eConfirmationConsult',
  'Analisar Cadastro' = 'analyzeRegister',
  'Adicionar Produto na Empresa' = 'createCompanyProducts',
  'Adicionar Tipo de Título na Empresa' = 'createCompanyTitleType',
  'Alterar Tipo de Título da Empresa' = 'updateCompanyTitleType',
  'Excluir Tipo de Título da Empresa' = 'deleteCompanyTitleType',
  'Adicionar Tarifas na Empresa' = 'createCompanyFees',
  'Alterar Tarifas da Empresa' = 'updateCompanyFees',
  'Excluir Tarifas da Empresa' = 'deleteCompanyFees',
  'Adicionar Grupo de Operadores na Empresa' = 'createCompanyOperatorGroup',
  'Alterar Grupo de Operadores na Empresa' = 'updateCompanyOperatorGroup',
  'Excluir Grupo de Operadores na Empresa' = 'deleteCompanyOperatorGroup',
  'Adicionar Tarifas em Tipos de Título' = 'createCompanyTitleTypeFare',
  'Atualizar Tarifas em Tipos de Título' = 'updateCompanyTitleTypeFare',
  'Deletar Tarifas em Tipos de Título' = 'deleteCompanyTitleTypeFare',
  'Adicionar Agente Cobrador' = 'createCompanyBillingAgents',
  'Atualizar Agente Cobrador' = 'updateCompanyBillingAgents',
  'Deletar Agente Cobrador' = 'deleteCompanyBillingAgents',
  'Atualizar Agente Cobrador Padrão' = 'updateCompanyDefaultBillingAgent',
  'Adicionar Feriados na Empresa' = 'createCompanyHollidays',
  'Atualizar Feriados na Empresa' = 'updateCompanyHollidays',
  'Deletar Feriados na Empresa' = 'deleteCompanyHollidays',
  'Ver Consultas do Cedente' = 'readAssignorConsults',
  'Atualizar Consultas do Cedente' = 'updateAssignorConsults',
  'Ver Debêntures (Root)' = 'readDebentures',
  'Adicionar Debênture' = 'createDebentures',
  'Atualizar Debênture' = 'updateDebentures',
  'Deletar Debênture' = 'deleteDebentures',
  'Transferir título para PDD' = 'transferTitleToPdd',
  'Ver Supervisores (Root)' = 'readSupervisors',
  'Adicionar Supervisores' = 'createSupervisors',
  'Atualizar Supervisores' = 'updateSupervisors',
  'Deletar Supervisores' = 'deleteSupervisors',
  'Transferir Cedentes de Supervisores' = 'transferAssignorToSupervisor',
  'Importar Operações do Facta' = 'importOperationsFromFacta',
  'Enviar Carta de Confirmação' = 'sendPayerLetterConfirmation',
  'Enviar Carta de Cobrança PEFIN' = 'sendLetterBillingPefin',
  'Enviar Carta de Cobrança Simples' = 'sendLetterBillingSimple',
  'Enviar Carta de Cobrança 1 FIDC' = 'sendLetterBillingFIDC',
  'Enviar Carta de Cobrança Cartório' = 'sendLetterBillingRegistry',
  'Enviar Carta de Cobrança Aviso de Apontamento de Cartório' = 'sendLetterBillingProtestAppointment',
  'Ver Conciliações FIDC (Root)' = 'viewFidcConciliations',
  'Adicionar Conciliação FIDC' = 'createFidcConciliations',
  'Ver Relatório de Cheques Devolvidos (Root)' = 'reportChecksReturned',
  'Ver Relatório de Operações (Root)' = 'reportOperations',
  'Ver Relatório de Acompanhamento de Cobrança (Root)' = 'reportBillingMonitoring',
  'Ver Relatório de Pendências Financeiras (Root)' = 'reportFinancialPendings',
  'Ver Relatório de Extrato do Cliente (Root)' = 'reportCustomerExtract',
  'Ver Relatório de Cálculo de Provisão (Root)' = 'reportProvisionCalc',
  'Ver Relatório de Retrato de Cedentes (Root)' = 'reportAssignorPortrayal',
  'Ver Relatório de Aquisições por Cedente (Root)' = 'reportAssignorAcquisitions',
  'Ver Relatório de Receitas (Root)' = 'reportRevenues',
  'Ver Relatório de Procuradores' = 'reportProcurators',
  'Ver Relatório de Acompanhamento de Carteira (Root)' = 'reportWalletMonitoring',
  'Ver Relatório de Apropriação de Receita (Root)' = 'reportRevenueAppropriation',
  'Ver Relatório de Confirmações (Root)' = 'reportConfirmations',
  'Ver Relatório de Títulos Pagos em Duplicidade (Root)' = 'reportTitlesPaidInDuplicity',
  'Ver Relatório de Praça de pagamento (Root)' = 'reportTitlesLocalPayment',
  'Ver Relatório de Títulos em Cobrança (Root)' = 'reportTitlesInBilling',
  'Ver Relatório de Títulos Prorrogados (Root)' = 'reportTitlesExtended',
  'Ver Relatório de Títulos Liquidados (Root)' = 'reportTitlesPaid',
  'Ver Relatório de Recibo por Tipo de Baixa (Root)' = 'reportReceiptByWriteOff',
  'Ver Relatório de Títulos Recomprados (Root)' = 'reportTitlesRebuy',
  'Ver Relatório de Títulos não Confirmados (Root)' = 'reportTitlesNotConfirmed',
  'Ver Relatório de Títulos em Aberto (Root)' = 'reportTitlesOpen',
  'Ver Relatório de Comissão de Gerentes' = 'reportCommission',
  'Ver Serviço de Consulta Serasa (Root)' = 'serviceSerasaConsult',
  'Atualizar Consulta Serasa' = 'serviceUpdateSerasaConsult',
  'Ver Serviço de Notificação/Cobrança (Root)' = 'serviceNotificationAndBilling',
  'Ver Serviço de Notificação Extrajudicial (Root)' = 'serviceExtrajuridicalNotification',
  'Ver Serviço de Envio de E-mail (Root)' = 'serviceEmailsSend',
  'Adicionar Descontos na Operação' = 'createOperationDiscounts',
  'Remover Descontos da Operação' = 'removeOperationDiscounts',
  'Estornar Pagamento de Contas a Pagar' = 'reverseInvoicePayments',
  'Alterar Pagamento de Comissão do Título' = 'changeTitlePayCommissions',
  'Ver Unicidade Nacional de Títulos' = 'readNationalUnicity',
  'Adicionar Unicidade Nacional de Títulos' = 'createNationalUnicity',
  'Opções no menu Relatórios Títulos (Root)' = 'submenuTileReport',
  'Opções no menu Relatórios Operacionais (Root)' = 'submenuOperationalReport',
  'Opções no menu Relatórios Financeiros (Root)' = 'submenuFinancialReport',
  'Opções no menu Relatórios Gerenciais (Root)' = 'submenuManagementReport',
  'Opções no menu Relatórios Cadastrais' = 'submenuRegisterReport',
  'Ver Relatório de Retrato da Carteira (Root)' = 'reportWalletPortrait',
  'Adicionar Documentos do Sacado' = 'addPayerDocuments',
  'Atualizar Documentos do Sacado' = 'updatePayerDocuments',
  'Deletar Documentos do Sacado' = 'deletePayerDocuments',
  'Adicionar Parecer' = 'addAssignorOpinion',
  'Aprovar Parecer' = 'approveAssignorOpinion',
  'Ver Relatório de Posição de Carteira (Root)' = 'reportWalletPosition',
  'Configurar Sacados de Títulos Comissária' = 'setUnnotifiedPayer',
  'Registrar Lançamento Futuro (Contas a Pagar)' = 'registerFutureInvoiceSends',
  'Registrar Lançamento Futuro' = 'createFutureSends',
  'Registrar Lançamento Futuro (Pendências)' = 'createFuturePendenciesSends',
  'Registrar Lançamento Futuro (Tarifas)' = 'registerFutureTariffSends',
  'Registrar Lançamento Futuro (Títulos)' = 'registerFutureTitleSends',
  'Ver Controler de Comissões' = 'viewCommissionControl',
  'Alterar Controler de Comissões' = 'updateCommissionControl',
  'Atualizar Dados Contábeis' = 'updateContabilData',
  'Ver Serviço de Serasa Reciprocidade (Root)' = 'readSerasaReciprocity',
  'Ver Registro de Títulos (QCertifica)' = 'readRegisteredTitles',
  'Ver Relatório de Lançamentos por Planos de Contas (Root)' = 'reportEntriesByAccountingPlans',
  'Ver Relatório de Ocorrências de Títulos (Root)' = 'reportTitlesOccurrencies',
  'Ver Relatório de Valor de Face (Root)' = 'reportFaceValue',
  'Ver Relatório de Fluxo de Caixa' = 'reportCashierFlow',
  'Ver Antecipação de Cartões' = 'cardAntecipation',
  'Ver Exportação de Cobrança Neofin' = 'readExportNeofin'
}

const items = (
  Object.keys(PermissionsEnum) as Array<keyof typeof PermissionsEnum>
)[0]

/**
 * Lista de permissões gerais do sistema
 */
export type PermissionsList = typeof items
